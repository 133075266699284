import {SelectionModel} from '@angular/cdk/collections';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {Router} from '@angular/router';
import {RiscCaseRequest as RiskCaseMessage} from '@shared/models/dto/risk-case-request.dto';
import {GraphUser} from '@shared/models/graphUser.model';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {NewRisk} from 'src/app/shared/models/new-risk.model';
import swal from 'sweetalert2';
import {BaseService, MsalService, StepperService} from '../../../../../shared/services';
import {Clipboard} from '@angular/cdk/clipboard';
import {dateFormat, RETURN_BACK_TO_RCSA_PARAM} from '@shared/common/constants';
import {eventStepList} from "../../../common-constants";
import {environment} from "../../../../../../environments/environment";

@Component({
  selector: 'app-summary-event',
  templateUrl: './summary-event.component.html',
  styleUrls: ['./summary-event.component.scss']
})
export class SummaryEventComponent implements OnInit, AfterContentChecked {
  viewStatus: string;
  hideRisks = true;
  @Input() isDesktopDevice: boolean;

  riskCaseId: number;

  today30 = new Date(); // Now
  @ViewChild('step10_View') MonetaryImpact;
  @ViewChild('legalEntitiesView') legalEntitiesView;
  riskCaseLoadData = false;
  riskCase: NewRisk;
  hideAdministration = true;
  modalRef: BsModalRef;
  GCOnly = true;
  loadDataFlag = true;
  controlRisks = [];
  changStatus = false;
  selection = new SelectionModel<any>(true, []);
  userRole = 'NAN';
  allUserRoles;
  allUserRolesShort: string[] = [];
  searchValueRCL2: string;
  displayPermalink = false;
  attachedFiles: any[] = [];
  RootCause = false;
  statusBarRiskCaseData;
  Lessons = false;
  riskCaseOverview;
  filterUsersSingleList: any[];
  today = new Date();
  numberOfItems = 3;
  numberOfItemsL = 3;
  dateFormat = dateFormat;
  modelView = '';
  MIList = [];
  nonMIList = [];
  uploadedList = [];
  outsourcingList = [];
  displayConfirmDialog = false;
  localReporting = [];
  complianceRiskCategory = [];
  scenarioLink = [];
  eventTypes1 = [];
  eventTypes2 = [];
  AllMIList = [];
  showBUI = false;
  resultGit;
  resultGitNew;
  creator = '';
  ownerId = '';
  manger = '';
  delegate = '';
  showError = false;
  loggedUser = '';
  additionalInfoRecipients = [];
  Toast = swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 10000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', swal.stopTimer);
      toast.addEventListener('mouseleave', swal.resumeTimer);
    }
  })
  ORM = '';
  GovernanceCoordinator = [];
  governanceCoordinators: GraphUser[] = [];
  riskManagers: GraphUser[] = [];
  FieldHelpData;
  riskStatus = '';
  rcsaOwner = {user_id: '', user_name: '', user_status: ''};
  statusList = [{id: 6, name: 'Draft', description: null, buttonName: 'Submit Draft for Review'},
    {
      id: 7,
      name: 'Draft - Pending Review',
      description: null,
      buttonName: 'Submit Draft for Review'
    },
    {id: 8, name: 'Open', description: null, buttonName: 'Submit Draft for Review'},
    {
      id: 9,
      name: 'Open - Pending Closure Approval',
      description: null,
      buttonName: 'Submit Draft for Review'
    },
    {id: 10, name: 'Closed', description: null, buttonName: 'Submit Draft for Review'},
    {id: 11, name: 'Cancelled', description: null, buttonName: 'Submit Draft for Review'},
    {id: 37, name: 'Pre-Draft', description: null, buttonName: 'Submit Draft for Review'},
  ];

  constructor(private stepperService: StepperService,
              private clipboard: Clipboard,
              private modalService: BsModalService,
              private baseService: BaseService,
              private msalService: MsalService,
              private cdref: ChangeDetectorRef,
              private router: Router) {
    this.FieldHelpData = this.stepperService.FieldHelpData;
    this.viewStatus = 'mainView';
    this.today30.setDate(this.today30.getDate() + 30); // Set now + 30 days as the new date
    this.riskCaseId = this.stepperService.newRisk.riskCaseId;

    this.loadRiskCaseById(this.riskCaseId);
    this.loadData();

    if (this.stepperService.permalinkText == 'not available') {
      this.stepperService.updatePermalinkText(window.location.origin + '/new-risk/additionalInformation');
    }
    this.baseService.get('additional-info-recipient?riskCaseId=' + this.riskCaseId).subscribe(res => {
      this.additionalInfoRecipients = res.body.data;
    });
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnInit(): void {
    this.stepperService.currentRcsas.subscribe(rcsas => {
      this.stepperService.checkRCSA(rcsas)
      this.governanceCoordinators = this.stepperService.governanceCoordinators;
      this.riskManagers = this.stepperService.riskManagers;
    });
  }

  reloadRiskCase() {
    this.loadRiskCaseById(this.riskCaseId);
    this.displayConfirmDialog = false;
  }

  showButtonForUser(validUsersList) {
    if (validUsersList.find(x => x === this.loggedUser)) {
      return true;
    } else {
      return false;
    }
  }

  loadData() {
    this.baseService.get('governance-coordinators/risk-case/' + this.riskCaseId).subscribe(res => {
      this.GovernanceCoordinator = res.body.data;
    });
    this.baseService.get('authorization/roles').subscribe(res => {
      this.allUserRoles = res.body.data;
      this.allUserRoles.forEach(r => {
        switch (r) {
          case 'ORM II':
            this.allUserRolesShort.push('ORMII');
            break;
          case 'Compliance':
            this.allUserRolesShort.push('Comp');
            break;
          case 'Governance Coordinator':
            this.allUserRolesShort.push('GC');
            break;
          default:
            this.allUserRolesShort.push(r);
        }
      });
      if (res.body.data.indexOf('CORM') >= 0) {
        this.userRole = 'CORM';
        return;
      }
      if (res.body.data.indexOf('ORM') >= 0) {
        this.userRole = 'ORM';
        return;
      }
      if (res.body.data.indexOf('ORM II') >= 0) {
        this.userRole = 'ORMII';
        return;
      }
      if (res.body.data.indexOf('Compliance') >= 0) {
        this.userRole = 'Comp';
        return;
      }
      if (res.body.data.indexOf('Governance Coordinator') >= 0) {
        this.userRole = 'GC';
        return;
      }
      if (res.body.data.indexOf('GIA') >= 0) {
        this.userRole = 'GIA';
        return;
      }
      if (this.userRole == 'NAN') {
        this.baseService.get('authorization/unit/' + this.riskCaseId).subscribe(resUM => {
          if (resUM.body) {
            this.userRole = 'UM'; //unit member
          }
        });
      }
    });
    this.baseService.get('enum/items/17').subscribe(res => {
      this.localReporting = res.body.data;
    });
    this.baseService.get('enum/items/19').subscribe(res => {
      this.complianceRiskCategory = [];
      res.body.data.forEach(element => {
        if (element.id !== 168) {
          this.complianceRiskCategory.push(element);
        }
      });
    });
    this.baseService.get('event-types/level1').subscribe(res => {
      this.eventTypes1 = res.body.data;
    });
    this.baseService.get('enum/items/18').subscribe(res => {
      this.scenarioLink = res.body.data;
    });
    this.baseService.get('enum/items/11').subscribe(res => {
      this.outsourcingList = res.body.data;
    });
  }

  loadDataSync() {
    this.baseService.get('risk-case-overview/' + this.riskCaseId).subscribe(res => {
      this.riskCaseOverview = res.body;
    });
    this.baseService.get('risk-case/' + this.riskCaseId + '/monetary-impacts').subscribe(res => {
      this.MIList = [];
      this.AllMIList = res.body.data;
      this.nonMIList = [];
      res.body.data.forEach(element => {
        if (element.monetary_impact_type.id === 91) {
          this.MIList.push(element);
        } else {
          this.nonMIList.push(element);
        }
      });
    });
  }

  getRiskStatus(status) {
    this.statusList.forEach(element => {
      if (element.id === status) {
        this.riskStatus = element.name;
      }
    });
  }

  loadRiskCaseById(id: number) {
    if (!id) {
      this.router.navigate(['/']);
      return;
    }

    this.loadDataFlag = false;
    this.controlRisks = [];
    this.rcsaOwner = {user_id: '', user_name: '', user_status: ''};
    this.loadDataSync();
    this.changStatus = false;
    this.baseService.get('risk-case/' + id).subscribe(res => {

      if (this.msalService.getLoggedUser()) {
        this.loggedUser = this.msalService.getLoggedUser().user_id;
      }

      this.stepperService.etag = res.headers.get('ETag').replace(/"/g, '');
      const riskCaseUpdatedData = res.body.risk_case;
      if (!this.stepperService.redirectFromMap && riskCaseUpdatedData.last_step < 99) {
        console.log('REDIRECT', this.stepperService.redirectFromMap);
        window.location.href = eventStepList[riskCaseUpdatedData.last_step].url + '?id=' + id;
        return;
      }

      this.baseService.get('risk-case/' + id + '/attachment').subscribe(res => {
        this.uploadedList = res.body.data;
      });

      riskCaseUpdatedData.risk_assessments = [];
      if (res.body.risk_assessment) {
        riskCaseUpdatedData.risk_assessments.push(res.body.risk_assessment);
        this.rcsaOwner = res.body.risk_assessment.rcsa_owner;
      }
      if (res.body.risks_primary_path) {
        riskCaseUpdatedData.risk = res.body.risks_primary_path;
      }
      if (this.checkList(res.body.risk_assessments_primary_path)) {
        riskCaseUpdatedData.risk_assessments = riskCaseUpdatedData.risk_assessments.concat(res.body.risk_assessments_primary_path);
        this.rcsaOwner = res.body.risk_assessments_primary_path[0].rcsa_owner;
      }
      this.riskCase = RiskCaseMessage.toModel(riskCaseUpdatedData);
      this.resultGit = riskCaseUpdatedData;
      this.resultGitNew = res.body;
      this.stepperService.newRisk = this.riskCase;
      this.stepperService.permalinkText = window.location.href;
      this.stepperService.setRcsa([this.stepperService.newRisk.mandate]);

      if (this.riskCase.creator) {
        this.creator = this.riskCase.creator.user_id;
      }
      if (this.resultGit.owner) {
        this.ownerId = this.resultGit.owner.user_id;
      }
      if (this.resultGit.risk_manager) {
        this.manger = this.resultGit.risk_manager.user_id;
      }
      if (this.resultGit.delegate) {
        this.delegate = this.resultGit.delegate.user_id;
      }
      this.baseService.get('risk-association-control/' + id).subscribe(controlRes => {
        this.controlRisks = controlRes.body.data;
      });
      if (this.riskCase.event_type_level_1) {
        this.baseService.get('event-types/level2/' + this.riskCase.event_type_level_1.id).subscribe(res => {
          this.eventTypes2 = res.body.data;
        });
      }
      this.getRiskStatus(this.riskCase.status);
      this.statusBarRiskCaseData = RiskCaseMessage.toDto(this.riskCase, true).risk_case;
      const element: HTMLElement = document.getElementById('refreshStatus');
      if (element) {
        element.click();
      }
      this.loadDataFlag = true;
      if (this.BiLists(this.riskCase.businessImpact).length > 0) {
        this.showBUI = true;
      }
    });
  }

  openModal(template: TemplateRef<any>) {
    const configs = {
      keyboard: false,
      ignoreBackdropClick: true,
      animated: true,
      class: ' modal-lg modal-primary informationModalSummary',
    };
    this.modalRef = this.modalService.show(template, configs);
  }

  nextStep() {
  }

  newCase() {
    this.stepperService.resetRiskCase();
    this.router.navigate(['/new-risk']);
  }

  editEntity(templet, view) {
    if (!view) {
      return;
    }
    if (view === 'attachment') {
      this.modelView = view;
      this.openModal(templet);
      return;
    }
    if (this.riskCase.status === 11 || this.riskCase.status === 10) {
      this.modelView = 'cantEdit';
      this.openModal(templet);
      return;
    }
    if (view === 'complianceRiskCategory') {
      if (this.showButtonForUserRole(['Comp', 'CORM', 'UM'])) {
        this.modelView = view;
        this.openModal(templet);
        return;
      } else {
        this.modelView = 'cantEdit';
        this.openModal(templet);
        return;
      }
    }
    if (this.showButtonForUserRole(['NAN']) && !this.showButtonForUser([this.ownerId, this.delegate, this.ORM, this.creator].concat(this.GovernanceCoordinator))) {
      this.modelView = 'cantEdit';
      this.openModal(templet);
      return;
    }
    if (view !== 'complianceRiskCategory' && this.showButtonForUserRole(['Comp'])
      && !this.showButtonForUser([this.ownerId, this.delegate, this.ORM].concat(this.GovernanceCoordinator))
      && !this.hasAnyOfRoles(['GC'])) {
      this.modelView = 'cantEdit';
      this.openModal(templet);
      return;
    }
    if (view === 'Risk') {
      if (this.showButtonForUserRole(['CORM', 'ORMII', 'ORM', 'GC', 'GIA'])) {
        this.modelView = 'Risks&ControlsHighProfile';
        this.openModal(templet);
        return;
      } else {
        this.modelView = 'Risks&ControlsLowProfile';
        this.openModal(templet);
        return;
      }
    }
    if (!this.showButtonForUser([this.ownerId, this.delegate, this.ORM].concat(this.GovernanceCoordinator))
      && !this.hasAnyOfRoles(['GC'])) {
      if (this.statusDisabled([7, 8, 9])) {
        if (this.showButtonForUser([this.creator]) && !this.showButtonForUserRole(['CORM', 'ORMII', 'ORM', 'GC'])) {
          this.modelView = 'cantEdit';
          this.openModal(templet);
          return;
        }
      }
      if (this.showButtonForUserRole(['Comp', 'UM']) && view !== 'complianceRiskCategory' && !this.showButtonForUser([this.creator])) {
        this.modelView = 'cantEdit';
        this.openModal(templet);
        return;
      }
      if (this.showButtonForUserRole(['Comp']) && !this.showButtonForUser([this.creator]) && (this.riskCase.status === 6 || this.riskCase.status === 7 || this.riskCase.status === 37)) {
        this.modelView = 'cantEdit';
        this.openModal(templet);
        return;
      }
      if (this.showButtonForUserRole(['GC']) && view !== 'event') {
        this.modelView = view;
        this.openModal(templet);
        return;
      }
    }
    this.getSelectionLocalReporting();
    this.openModal(templet);
    this.modelView = view;
  }

  cantEdit(event): boolean {
    return false;
  }

  BiLists(allLists) {
    let newList = [];
    if (!allLists) {
      return [];
    }
    if (allLists.lifeCapital) {
      newList = newList.concat(allLists.lifeCapital);
    }
    if (allLists.corso) {
      newList = newList.concat(allLists.corso);
    }
    if (allLists.reinsurance) {
      newList = newList.concat(allLists.reinsurance);
    }
    if (allLists.noValue) {
      newList = newList.concat(allLists.noValue);
    }
    return newList;
  }

  selectUser(data, field) {
    this.riskCase.roles[field] = data;
  }

  filterUsersSingle(event) {
    const query = event.query;
    if (query.length > 2) {
      this.filterUsersSingleList = [];
      this.baseService.getUsers('user?q=' + query).then(users => {
        this.filterUsersSingleList.push(...users);
      });
    } else {
      this.filterUsersSingleList = [];
    }
  }

  composeName(user: any) {
    return user.user_name + ' (' + user.user_id + ')';
  }

  cancel() {
    this.loadDataFlag = false;
    this.loadRiskCaseById(this.riskCaseId);
    this.modalRef.hide();
  }

  update() {
    const req = RiskCaseMessage.toDto(this.riskCase);
    req.risk_case.permalink = this.stepperService.permalinkText;
    this.loadDataFlag = false;
    this.baseService.put('risk-case', req, this.stepperService.etag, this.riskCaseId).subscribe(data => {
        this.getRiskStatus(data.body.risk_case.status.id);
        this.stepperService.etag = data.headers.get('ETag').replace(/"/g, '');
        if (this.changStatus) {
          this.showTHYDialog();
        } else {
          this.loadRiskCaseById(this.riskCaseId);
        }
      }, error => {
        console.log(error);
        if (error.status == '409') {
          //display reload dialog
          this.displayConfirmDialog = true;
        } else {
          this.loadRiskCaseById(this.riskCaseId);
          this.Toast.fire({
            icon: 'error',
            title: `Sorry, ${this.riskCase.source_name} ${this.riskCase.event.title} not saved`,
          })
        }
      },
      () => {
      });
    this.modalRef.hide();
  }

  showTHYDialog() {
    this.Toast.fire({
      icon: 'info',
      title: `Thank you, ${this.riskCase.source_name} ${this.riskCase.event.title} is now in status ${this.riskStatus}`,
    })
    this.stepperService.resetRiskCase();
    this.msalService.riskCaseId = 0;
    this.stepperService.loadRcsaByCurrentUser();
    setTimeout(() => {
      window.location.href = environment.rcsaUrl + RETURN_BACK_TO_RCSA_PARAM;
    }, 5000);
  }

  updateMI_view() {
    this.MonetaryImpact.getMI_Items();
    this.loadRiskCaseById(this.riskCaseId);
    this.modalRef.hide();
  }

  updateData(data) {
    this.riskCase[data.prop] = data.propValue;
  }

  updateDataAndSave(data) {
    this.riskCase[data.prop] = data.propValue;
    this.update();
  }

  dateChanged(evt, row) {
    this.riskCase.event[row] = new Date(evt);
    this.checkDate();
  }

  checkDate() {
    this.showError = false;
    for (const iterator of this.AllMIList) {
      if (iterator.monetary_impact_type.id != 212) {
        if (new Date(iterator.bus_date).getTime() < new Date(this.riskCase.event.discoveryDate).getTime()) {
          this.showError = true;
          return;
        }
      }
    }
  }

  changeStatus(statusData) {
    this.changStatus = true;
    this.riskCase.lastStep = 99;
    this.riskCase.status = statusData.status.id;
    this.riskCase[statusData.commentAttr] = statusData.comment;
    if (statusData.userValue) {
      this.riskCase[statusData.userName] = statusData.userValue;
    }
    if (statusData.dateValue) {
      this.riskCase[statusData.dateName] = statusData.dateValue;
    }
    this.update();
  }

  goToStep() {
    this.stepperService.loadRiskCaseById(this.riskCaseId);
  }

  outsourse(selectedOutsourcing) {
    let name = '';
    if (this.outsourcingList) {
      this.outsourcingList.forEach(element => {
        if (element.id === selectedOutsourcing) {
          name = element.name;
        }
      });
    }
    return name;
  }

  checkValidation() {
    if (new Date(this.riskCase.event.discoveryDate).getTime() < new Date(this.riskCase.event.occurredDate).getTime()) {
      return {disabled: true, msg: 'Event Discovery Date should be after Event Occurred Date'};
    }
    if (this.showError) {
      return {disabled: true, msg: ''};
    }
    if (!this.riskCase.roles.eventOwner) {
      return {disabled: true, msg: 'Event Owner is Mandatory'};
    }
    if (this.riskCase.roles.eventOwner && this.riskCase.roles.eventDelegate) {
      if (this.riskCase.roles.eventOwner.user_id === this.riskCase.roles.eventDelegate.user_id) {
        return {disabled: true, msg: "Event Owner and Event Delegate can't be the same person"};
      }
    }
    return {disabled: false, msg: ''};
  }

  hidePermalinkDialog() {
    this.displayPermalink = false;
  }

  showPermalinkDialog() {
    this.displayPermalink = true;
  }

  hideAttachmentDialog() {
    this.modalRef.hide();
    this.baseService.get('risk-case/' + this.riskCaseId + '/attachment').subscribe(res => {
      this.uploadedList = res.body.data;
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.riskCase.local_reporting.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.riskCase.local_reporting.forEach(row => this.selection.select(row));
    this.isSomeSelected();
  }

  isSomeSelected() {
    this.riskCase.local_reporting = this.selection.selected;
    return this.selection.selected.length > 0;
  }

  getSelectionLocalReporting() {
    this.riskCase.local_reporting.forEach(row => {
      this.selection.select(row);
    });
  }

  getList(list, listName) {
    this.riskCase[listName] = list;
  }

  setAdditionalInformationData(value, property) {
    this.riskCase[property] = value;
  }

  seteventTypes2(data) {
    if (data) {
      this.riskCase.event_type_level_1 = {id: data.level_1_id, name: data.level_1_name};
      this.baseService.get('event-types/level2/' + data.level_1_id).subscribe(res => {
        this.eventTypes2 = res.body.data;
      });
    } else {
      this.riskCase.event_type_level_1 = null;
      this.eventTypes2 = [];
    }
    this.setAdditionalInformationData(null, 'event_type_level_2');
  }

  SearchRCL2(payLoad: any): void {
    this.searchValueRCL2 = payLoad;
  }

  showButtonForUserRole(validUsersList) {
    if (validUsersList.find(x => x === this.userRole)) {
      return true;
    } else {
      return this.hasAnyOfRoles(validUsersList);
    }
  }

  private hasAnyOfRoles(validRoles: string[]) {
    let result = false;
    this.allUserRolesShort.forEach(role => {
      if (validRoles.includes(role)) {
        result = true;
      }
    });
    return result;
  }

  cantEditTextArea() {
    if (this.riskCase.status === 11 || this.riskCase.status === 10) {
      return true;
    }
    if (this.riskCase.status === 7 || this.riskCase.status === 8 || this.riskCase.status === 9) {
      if (!this.showButtonForUser([this.ownerId, this.delegate, this.ORM])) {
        if (this.showButtonForUser([this.creator]) && !this.showButtonForUserRole(['CORM', 'ORMII', 'ORM', 'GC'])) {
          return true;
        }
      }
    }
    return !(this.showButtonForUserRole(['CORM', 'ORM', 'ORMII', 'GC']) || this.showButtonForUser([this.creator, this.ownerId, this.delegate, this.ORM]));
  }

  nocomplianceRiskCategory(checked, others) {
    const noCRC = {
      description: null,
      enum_type: {id: 19, name: 'Compliance Risk Category', description: null},
      id: 168,
      name: 'Not Compliance and Legal Risk related'
    }
    if (checked) {
      this.riskCase.compliance_risk_category = [noCRC];
      others.selection.clear();
    } else {
      this.riskCase.compliance_risk_category = [];
    }
  }

  checkNotCC(list, id) {
    if (!list || list.length === 0) {
      return false;
    } else {
      for (const iterator of list) {
        if (iterator.id === id) {
          return true;
        }
      }
      return false;
    }
  }

  statusDisabled(validStatusList) {
    if (validStatusList.find(x => x === this.riskCase.status)) {
      return true;
    } else {
      return false;
    }
  }

  dateValidate(date) {
    if (!date) {
      return;
    }
    let newDate;
    if (typeof date == 'object' && date.length > 0) {
      newDate = date.join('/');
    } else if (typeof date == 'string' && date.length > 0) {
      newDate = date.substring(0, 10);
      newDate = newDate.replace(/-/g, '/');
      return new Date(newDate);
    } else {
      newDate = date;
    }
    return new Date(newDate);
  }

  checkRequired(validStatusList, data) {
    if (data) {
      return false;
    }
    let status = 0;
    if (this.showButtonForUserRole(['CORM', 'ORMII', 'ORM']) && this.statusDisabled([6, 8])) {
      status = this.riskCase.status + 1;
    } else {
      status = this.riskCase.status;
    }
    if (validStatusList.find(x => x === status)) {
      return true;
    } else {
      return false;
    }
  }

  copyGraceUrl(url) {
    this.clipboard.copy(url);
  }

  goOutsideApp(url) {
    window.open(url, '_blank');
  }

  tooltip(toolTipontant, position) {
    const tooltipData = {
      allowHTML: true,
      delay: [1200, null],
      animation: 'shift-away',
      placement: position,
      theme: 'light',
      content: toolTipontant
    };
    return tooltipData;
  }

  checkEditEntity(view) {
    if (this.riskCase.status === 11 || this.riskCase.status === 10) {
      return false;
    }
    if (!this.isDesktopDevice) {
      return false;
    }
    if (view === 'lessonLearnDescription' || view === 'rootCauseDescription' || view === 'description') {
      return !this.cantEditTextArea();
    }
    if (view === 'complianceRiskCategory') {
      if (this.showButtonForUserRole(['Comp', 'CORM', 'UM'])) {
        return true;
      } else {
        return false;
      }
    }
    if (this.showButtonForUserRole(['NAN', 'GIA']) && !this.showButtonForUser([this.ownerId, this.delegate, this.ORM, this.creator].concat(this.GovernanceCoordinator))) {
      return false;
    }
    if (view !== 'complianceRiskCategory'
      && this.showButtonForUserRole(['Comp'])
      && !this.showButtonForUser([this.ownerId, this.delegate, this.ORM].concat(this.GovernanceCoordinator))
      && !this.hasAnyOfRoles(['GC'])
    ) {
      return false;
    }
    if (view === 'Risk') {
      return true;
    }
    if (!this.showButtonForUser([this.ownerId, this.delegate, this.ORM].concat(this.GovernanceCoordinator))
      && !this.hasAnyOfRoles(['GC'])) {
      if (this.statusDisabled([7, 8, 9])) {
        if (this.showButtonForUser([this.creator]) && !this.showButtonForUserRole(['CORM', 'ORMII', 'ORM', 'GC'])) {
          return false;
        }
      }
      if (this.showButtonForUserRole(['Comp', 'UM']) && view !== 'complianceRiskCategory' && !this.showButtonForUser([this.creator])) {
        return false;
      }
      if (this.showButtonForUserRole(['Comp']) && !this.showButtonForUser([this.creator]) && (this.riskCase.status === 6 || this.riskCase.status === 7 || this.riskCase.status === 37)) {
        return false;
      }
      if (this.showButtonForUserRole(['GC']) && view !== 'event') {
        return true;
      }
    }
    return true;
  }

  sortBy(list, prop) {
    return list.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
  }

  checkRisksANdRcsaId(listItem, primaryItem) {
    if (!listItem || !primaryItem) {
      return true;
    }
    if (listItem.id != primaryItem.id) {
      return true;
    }
    return false;
  }

  byteCount(textValue, maxlength) {
    if (!textValue) {
      return {valid: true, length: 0};
    }
    if (encodeURI(textValue).split(/%..|./).length - 1 > maxlength) {
      return {valid: false, length: encodeURI(textValue).split(/%..|./).length - 1};
    }
    return {valid: true, length: encodeURI(textValue).split(/%..|./).length - 1};
  }

  checkList(list) {
    if (!list) {
      return false;
    }
    if (list.length == 0) {
      return false;
    }
    return true;
  }

  getControl(ctrlId) {
    let obj;
    if (this.controlRisks.find(o => o.ctrl_id === ctrlId)) {
      obj = this.controlRisks.find(o => o.ctrl_id === ctrlId);
    }
    return obj;
  }
}
